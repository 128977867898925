<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router/auto";
import useDamageReports from "../../../hooks/useDamageReports.js";
import useLps from "../../../hooks/useLps.js";
import { setPageTitle } from "@/utils/pageTitle";
import DamageReportsLayout from "@/layouts/DamageReportsLayout.vue";

const route = useRoute();

const { fetchDamage, damage } = useDamageReports();
const { fetchLp, fetchLpItemTransactions } = useLps();
const sourceTx = ref(null);
const destinationTx = ref(null);

onMounted(async () => {
  await fetchDamage(route.params.id);
  sourceTx.value = await fetchLpItemTransactions(damage.value.lp_id);
  if (damage.value.destination_lp_id) {
    destinationTx.value = await fetchLpItemTransactions(damage.value.destination_lp_id);
  }
  setPageTitle(`Damage Report // ${damage.value.id} Transactions`);
});
</script>

<template>
  <DamageReportsLayout v-if="damage" :damage="damage" title="Transactions">
    <v-card flat variant="text" class="rounded-0">
      <v-card-title class="bg-surface-light">Source Pallet Transactions</v-card-title>
      <v-table class="border-t-md">
        <thead>
          <tr class="bg-surface-light">
            <th class="text-left border-b-sm">ID</th>
            <th class="text-left border-b-sm">Quantity</th>
            <th class="text-left border-b-sm">Order</th>
            <th class="text-left border-b-sm">Time</th>
            <th class="text-left border-b-sm">Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!sourceTx || sourceTx.length == 0">
            <td colspan="12" class="text-center">No transactions found</td>
          </tr>
          <tr v-for="transaction in sourceTx" :key="transaction.id">
            <td>
              <a :href="`/lps/${transaction.id}`">{{ transaction.id }}</a>
            </td>
            <td>{{ transaction.qty }}</td>
            <td>
              <a
                v-if="transaction.order_type == 'Models::InboundOrder'"
                :href="`/inbound-orders/${transaction.order.id}`"
                >{{ transaction.order.number }}</a
              >
              <a
                v-else-if="transaction.order_type == 'Models::OutboundOrder'"
                :href="`/outbound-orders/${transaction.order.id}`"
                >{{ transaction.order.number }}</a
              >
            </td>
            <td>{{ new Date(transaction.created_at).toLocaleString("en-US") }}</td>
            <td>{{ transaction.notes }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
    <v-card flat variant="text" class="rounded-0">
      <v-card-title class="bg-surface-light"
        >Destination Pallet Transactions</v-card-title
      >
      <v-table class="border-t-md">
        <thead>
          <tr class="bg-surface-light">
            <th class="text-left border-b-sm">ID</th>
            <th class="text-left border-b-sm">Quantity</th>
            <th class="text-left border-b-sm">Order</th>
            <th class="text-left border-b-sm">Time</th>
            <th class="text-left border-b-sm">Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!destinationTx || destinationTx.length == 0">
            <td colspan="12" class="text-center">No transactions found</td>
          </tr>
          <tr v-for="transaction in destinationTx" :key="transaction.id">
            <td>
              <a :href="`/lps/${transaction.id}`">{{ transaction.id }}</a>
            </td>
            <td>{{ transaction.quantity }}</td>
            <td>
              <a
                v-if="transaction.order_type == 'Models::InboundOrder'"
                :href="`/inbound-orders/${transaction.order.id}`"
                >{{ transaction.order.number }}</a
              >
              <a
                v-else-if="transaction.order_type == 'Models::OutboundOrder'"
                :href="`/outbound-orders/${transaction.order.id}`"
                >{{ transaction.order.number }}</a
              >
            </td>
            <td>{{ new Date(transaction.created_at).toLocaleString("en-US") }}</td>
            <td>{{ transaction.notes }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
  </DamageReportsLayout>
</template>

<style scoped>
.text-h5 {
  /*needs to be inside scope different font used in other components */
  font-family: "Source Sans 3", "sans-serif" !important;
  font-size: 1rem !important;
  font-weight: bold;
}
</style>
